import { useContext } from 'react'
import AppContext from '../../context/AppContext'

export default function Header() {
  const { account, disconnect } = useContext(AppContext)

  return (
    <header>
      <div className='contentWrapper'>
        <div className='left'>
          <img src='/logo.png' alt='FirstBatch Logo' />
          <span>FirstBatch</span>
        </div>
        <div className='right'>
          {account.address && (
            <>
              <span>
                {account?.address.slice(0, 6)}....
                {account?.address.slice(-4)}
              </span>
              <button onClick={disconnect}>Disconnect</button>
            </>
          )}
        </div>
      </div>
    </header>
  )
}
