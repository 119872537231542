import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <div className='layoutContainer'>
      <div className='backgroundContainer'>
        <img src='/bgGradient.png' alt='Background Gradient' />
      </div>
      <div className='userWarningContainer'>
        <div className='title'>
          <img src='/logo.png' alt='FirstBatch Logo' />
          <span>FirstBatch</span>
        </div>
        <div className='text'>This page does not exists.</div>
        <Link to='/'>
          <button>Home</button>
        </Link>
      </div>
    </div>
  )
}
