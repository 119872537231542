import { ConnectButton } from '@rainbow-me/rainbowkit'

export default function CustomRKButton() {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated')

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}>
            {(() => {
              if (!connected) {
                return (
                  <button onClick={openConnectModal} type='button'>
                    Connect
                  </button>
                )
              }

              return null
            })()}
          </div>
        )
      }}
    </ConnectButton.Custom>
  )
}
