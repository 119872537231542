import { useContext } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useAccount } from 'wagmi'
import AppContext from '../../context/AppContext'
import Header from './Header'
import UserWarning from './UserWarning'

export default function Layout({ children }) {
  const {
    account,
    wChain,
    wConnector,
    taskIdInterval,
    isAccUsed,
    isPendingTaskId,
    isWrongConnector,
  } = useContext(AppContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const taskId = queryParams.get('task_id')

  // eslint-disable-next-line no-unused-vars
  const acc = useAccount({
    onConnect({ address, connector, isReconnected }) {
      if (taskId !== null) {
        taskIdInterval({ taskId, connector: connector.id })
        searchParams.delete('task_id')
        setSearchParams(searchParams)
      }
    },
  })

  const isWrongChain = wConnector === 'metaMask' && wChain !== 1

  return (
    <main className='layoutContainer'>
      <div className='backgroundContainer'>
        <img src='/bgGradient.png' alt='Background Gradient' />
      </div>
      {isAccUsed ? (
        <UserWarning type={'usedAcc'} />
      ) : isWrongConnector ? (
        <UserWarning type={'wrongConnector'} />
      ) : isPendingTaskId ? (
        <UserWarning type={'taskIdPending'} />
      ) : account.address ? (
        isWrongChain ? (
          <UserWarning type={'wrongChain'} />
        ) : (
          <>
            <Header />
            <section className='scrollContainer'>
              <section className='pageContent'>{children}</section>
            </section>
          </>
        )
      ) : (
        <UserWarning type={'notAuthorized'} />
      )}
    </main>
  )
}
